import { ImpressumComponent } from './../pages/impressum/impressum.component';
import { DetailsComponent } from './../pages/details/details.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartComponent } from './../pages/Start/Start.component';
import { LadestationenComponent } from './../pages/ladestationen/ladestationen.component';
import { DatenschutzComponent } from './../pages/datenschutz/datenschutz.component';
import { ReferenzenComponent } from './../pages/referenzen/referenzen.component';
import { WerbenComponent } from './../pages/werben/werben.component';

const routes: Routes = [
    { path: '', component: StartComponent },
    { path: 'produkte', component: LadestationenComponent },
    { path: 'produkte/:produkt', component: DetailsComponent },
    { path: 'werbung-schalten', component: WerbenComponent },
    { path: 'standortpartner-werden', redirectTo: 'referenzen' },
    { path: 'referenzen', component: ReferenzenComponent },
    { path: 'impressum', component: ImpressumComponent },
    { path: 'datenschutz', component: DatenschutzComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
