import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-progressLine',
  templateUrl: './progressLine.component.html',
  styleUrls: ['./progressLine.component.css']
})
export class ProgressLineComponent implements OnInit {

    @Input() value: number;

    constructor() { }

    ngOnInit() {
    }

}
