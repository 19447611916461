import { UtilitiesService } from './../../services/utilities.service';
import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-Start',
  templateUrl: './Start.component.html',
  styleUrls: ['./Start.component.css']
})
export class StartComponent implements OnInit {

    clients = [
        'pluscity',
        'hinterstoder',
        'alpbachtal',
        'linzag',
        'raiffeisen',
        'magna',
        'fhooe',
        'keba',
    ];

    video:any;

    constructor(private util:UtilitiesService) {
            this.util.setMeta(
              'Handyladestationen aus Österreich für alle Modelle',
              'Abschließbare Handyladestationen aus Österreich. Kompatibel mit allen Smartphones (iPhone, Android, USB-C). Der ideale Service für Ihre Kunden.'
            );
        }

    ngOnInit() { }

    startScroll() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#heading-container").offset().top - 300
        }, 800);
    }

}
