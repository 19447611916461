import { EmailKontaktService } from './../../services/emailKontakt.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailForm',
  templateUrl: './emailForm.component.html',
  styleUrls: ['./emailForm.component.css']
})
export class EmailFormComponent implements OnInit {

    constructor(public emailService: EmailKontaktService) { 
        
    }

    ngOnInit() {
    }

    toggleAgreement(checked:boolean) {
        this.emailService.agreementChecked = checked;
    }

}
