import { UtilitiesService } from './../../services/utilities.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css']
})
export class DatenschutzComponent implements OnInit {

  constructor(private util: UtilitiesService) { 
      this.util.setMeta('Datenschutz');
  }

  ngOnInit() {
  }

}
