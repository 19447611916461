import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LadestationenService {

    products = [
        {
            label: 'Charger 43',
            link:'/produkte/charger43',
            linkslug: 'charger43',
            imageurl: 'charger43',
            secondimageurl: 'charger43-zoom',
            type: 'Standmodell',
            attributes: ['Zentrales Codefeld', 'Individuell folierbar', 'Für Apple, Samsung, Huawei, etc.', 'Sehr elegantes Modell', '43“ Display', 'Digitale Infovermittlung', 'Maße: 190 x 75 x 45 cm']
        },
        {
            label: 'Charger 6/8',
            link:'/produkte/charger68',
            linkslug: 'charger68',
            imageurl: 'charger8',
            secondimageurl: 'charger6-hand',
            type: 'Wand- / Einbaumodell',
            attributes: ['Mit 6 oder 8 Schließfächern verfügbar', 'Eigenes Codefeld je Fach', 'Individuell folierbar', 'Für Apple, Samsung, Huawei, etc.', 'Robuste Bauweise', 'Selbsterklärende Bedienung und zusätzliche Schritt-für-Schritt Anleitung', 'Maße: 48,5 x 60,5 x 20 cm']
        },
        {
            label: 'Charger Mini',
            link:'/produkte/chargermini',
            linkslug: 'chargermini',
            imageurl: 'chargermini',
            secondimageurl: 'chargermini-seitlich',
            type: 'Wand- / Einbaumodell',
            attributes: ['Intuitives Bedienfeld', 'Individuell folierbar', 'Für Apple, Samsung, Huawei, etc.', 'Robuste Bauweise', 'Selbsterklärende Bedienung und zusätzliche Schritt-für-Schritt Anleitung', 'Maße: 48 x 36,8 x 11,5 cm']
        },
        {
            label: 'Ladetisch',
            link:'/produkte/tisch',
            linkslug: 'tisch',
            imageurl: 'ladetisch',
            secondimageurl: 'ladetisch-2',
            type: 'Stehtisch',
            attributes: ['Sehr große Brandingfläche', 'Stabil und einfach zu transportieren', 'Für Apple, Samsung, Huawei, etc.', 'Wireless Ladefläche inkludiert', 'Maße: 59 x 59 x 100 cm']
        },
    ];

    constructor() { }

}
