import { CookieNotificationService } from './../../services/cookieNotification.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookieNotification',
  templateUrl: './cookieNotification.component.html',
  styleUrls: ['./cookieNotification.component.css']
})
export class CookieNotificationComponent implements OnInit {

    constructor( public cookie: CookieNotificationService ) { 
        this.cookie.checkCookie()
    }

    ngOnInit() {
    }

}
