import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from './../../services/utilities.service';

@Component({
  selector: 'app-referenzen',
  templateUrl: './referenzen.component.html',
  styleUrls: ['./referenzen.component.css']
})
export class ReferenzenComponent implements OnInit {

    referenzen = [
        {
            imgsrc: 'assets/referenzen/LinzAG.jpg',
            imgalt: 'Ladestation im Schwimmbad',
            title: 'Linz AG - Freizeiteinrichtungen',
            text: 'Ob Winter oder Sommer. Die Linz AG bietet in ihren Freizeiteinrichtungen von Schwimmen im Frei- oder Hallenbad über Eislaufen bis hin zu Wellness für jeden das Richtige. Mitte 2019 wurden alle Freizeiteinrichtung der Linz AG mit Handy Ladestationen des Modells Charger43 ausgestattet. ',
            tags: '#Relax #Action #Linz',
            link: 'https://www.linzag.at/portal/de/privatkunden/freizeit',
            linkname: 'Link: LinzAG'
        },
        {
            imgsrc: 'assets/referenzen/Ischgl.png',
            imgalt: 'Ladestation',
            title: 'Ischgl - DIE Destination in den Alpen',
            text: '„Relax. If you can…Sport-Action-Excitement“. Mit diesem Spruch wirbt die Top Winter- und Sommerdestination Ischgl. Damit dabei der Handy Akku nie ausgeht wurden von der Silvretta Seilbahn AG und Chargeandgo sämtliche Bergrestaurants mit Handy Ladestationen des Modells Charger 8 ausgestattet.',
            tags: '#Sport #Action #Excitement',
            link: 'https://www.ischgl.com/de',
            linkname: 'Link: Ischgl - Skigebiet'
        },
        {
            imgsrc: 'assets/referenzen/Avantgarde.jpg',
            imgalt: 'Ladestation',
            title: 'Avantgarde - The leading global brand experience agency',
            text: 'Ob Promotion-Projekte, Eventmanagement oder strategische Planung: Avantgarde in Wien bringt die perfekte Symbiose aus Leidenschaft und Fachwissen mit, um Markenerlebnisse am Puls der Zeit zu erschaffen. Für eine Veranstaltung sollte ein extra Service geboten werden. Die Wahl viel auf einen Charger 6 mit schlichtem Branding.',
            tags: '#10 Standorte #7 Länder #700 Experten',
            link: 'https://avantgarde.net',
            linkname: 'Link: Avantgarde - Agentur'
        },
        {
            imgsrc: 'assets/referenzen/Mensa.JPG',
            imgalt: 'Ladestation in der Mensa',
            title: '„Mensa“ - Österreichische Mensen ',
            text: 'Die österreichischen Mensen versorgen österreichweit Studierende an Hochschulen mit Energie in Form von gutem Essen und Snacks. Gemeinsam mit Media in Progress werden nun auch die Handy Akkus der Studierenden mit Energy durch Chargeandgo Modell 43 versorgt. ',
            tags: '#MIP #Mensa #Hochschule',
            link: 'https://www.mensen.at',
            linkname: 'Link: Mensen'
        },
        {
            imgsrc: 'assets/referenzen/Alpbach.png',
            imgalt: 'Ladestation freigestellt',
            title: 'Alpbach - Ski Juwel',
            text: 'Skifahren im Winter, Wandern im Sommer. Das Alpbachtal bietet für jeden etwas. In der Talstation der Wiedersbergerhornbahn steht Österreichs höchstgelegene Handy Ladestation und versorgt Gäste während oder nach einem erfolgreichen Ski/Wandertag mit Energy.',
            tags: '#Alpbachtal #Wildschönau #Onthetop',
            link: 'https://www.skijuwel.com/de',
            linkname: 'Link: Alpbach - Skigebiet'
        },
        {
            imgsrc: 'assets/referenzen/Sepia.jpg',
            imgalt: 'Ladestation in einer Bar',
            title: 'Sepia - Food, Drinks, Entertainment',
            text: 'Ein Lokal der Sepia Gmbh wurde mit einem Charger 6 mit Münzsystem ausgestattet. So rechnet sich eine Investition in eine Handy Ladestation nicht nur in Sachen Sympathie sondern auch finanziell.',
            tags: '#Bar #Münzer #Charger 6',
            link: 'https://sepia.at',
            linkname: 'Link: Sepia'
        },
        {
            imgsrc: 'assets/referenzen/hinterstoder-wurzeralm.jpg',
            imgalt: 'Ladestation im Skigebiet',
            title: 'Hinterstoder - Skigebiet',
            text: 'Direkt in der Bärenalm auf der Mittelstation des Skigebiets Hinterstoder im Süden von Oberösterreich befindet sich seit Herbst 2018 ein Charger 43. Die Gäste werden über den Bildschirm mit aktuellen Infos versorgt und können nebenbei ihr Handy gratis Laden. Ein super Service in der kalten Jahreszeit.',
            tags: '#Snow #Kalt #Akku',
            link: 'http://www.hiwu.at/hiwu/de',
            linkname: 'Link: Hinterstoder - Skigebiet'
        },
        {
            imgsrc: 'assets/referenzen/pluscity.jpg',
            imgalt: 'Ladestation im Einkaufszentrum',
            title: 'Plus City - Einkaufszentrum',
            text: 'Die neuen Loungen in der Plus City überzeugen nicht nur optisch. Auch funktionell sind sie am neuesten Stand der Technik. Vollendet werden sie mit den Handyladestationen Modell Charger 8 und Stehtischen zum Handy laden.',
            tags: '#Real #Shopping #Lounge',
            link: 'https://www.pluscity.at',
            linkname: 'Link: Plus City'
        },
        {
            imgsrc: 'assets/referenzen/obi.jpg',
            imgalt: 'Ladestation im Baumarkt',
            title: 'Obi - Baumarkt',
            text: 'Österreichs modernster Baumarkt verfügt direkt am Haupteingang über eine Ladestation des Modells Charger 6. On- und Offline wird immer mehr verbunden. Auch in Baumärkten. Mit Chargeandgo steht diesem Trend nichts im Wege. ',
            tags: '#Alles #Machbar',
            link: 'https://www.obi.at/baumarkt/mattersburg/',
            linkname: 'Link: Obi'
        },
        {
            imgsrc: 'assets/referenzen/stiegl.jpg',
            imgalt: 'Ladestation auf der Gastromesse',
            title: 'Stiegl - Gast Messe Salzburg',
            text: 'Die Gast Messe bedarf keiner extra Erklärung. Stiegl hat erkannt, dass es bei den Gästen am Stand auch mal länger werden kann. Der Charger 43 mit seinen abschließbaren Ladefächern versorgt die Gäste mit Akku oder kann einfach auch mal genutzt werden um abzuschalten. Handy wegsperren und in Ruhe ein Bier genießen.',
            tags: '#Brauerei #Auszeit #AllesfürdenGast',
            link: 'https://www.stiegl.at/de',
            linkname: 'Link: Stiegl Brauerei'
        },
        {
            imgsrc: 'assets/referenzen/hr.jpg',
            imgalt: 'Ladestation auf der HR-Messe',
            title: 'HR Inside Summit - HR Event',
            text: 'Österreichs größtes HR Event in der Wiener Hofburg sucht seinesgleichen. Perfekt durchgeplant folgt im Rahmen des Programms ein Workshop auf den nächsten. Das fordert. Sowohl Mensch als auch Technik. In den unzähligen Loungen lässt es sich zwischendurch gut entspannen und der Handyakku kann dabei auch aufgeladen werden.',
            tags: '#HR #Personalentwicklung #Recruiting',
            link: 'https://www.hrsummit.at',
            linkname: 'Link: HR Summit'
        },
        {
            imgsrc: 'assets/referenzen/linzag-tennis.jpg',
            imgalt: 'Ladestation bei einem Tennisturnier',
            title: 'Linz AG - Tennisturnier und Bäder',
            text: 'Die Linz AG kümmert sich als Multi-Utility-Anbieter um die Energieversorgung. Dazu gehört auch das Ziel eines vollen Akkus für die Kunden. So kommen die Charger 43 immer wieder bei Veranstaltungen zum Einsatz und auch die 5 Bäder der Linz AG verfügen seit Frühjahr 2019 rechtzeitig vor Eröffnung der Saison über Handy Ladestationen. ',
            tags: '#Königliche #Vorteile #Liwest',
            link: 'https://www.linzag.at/portal/de/privatkunden',
            linkname: 'Link: Linz AG'
        },
        {
            imgsrc: 'assets/referenzen/fh-steyr.jpg',
            imgalt: 'Ladestation am Campus FH Steyr - Management',
            title: 'FH Steyr - Management',
            text: 'Langer Tag voll mit Vorlesungen und die Taschen sind jetzt schon gerammelt voll? Ab dem Sommer Semester 2018 ersparen sich Studenten dank Chargeandgo zumindest das Handy Ladekabel. Neben Infos von der FH, ÖH und dem Alumni-Club erhalten auch Firmen die Möglichkeit, direkt am Campus mit den Studenten via Jobanzeigen oder einer kurzen Firmenpräsentation in Kontakt zu treten. ',
            tags: '#Aula #Infopoint #Aufmerksamkeit',
            link: 'https://www.fh-ooe.at/campus-steyr/',
            linkname: 'Link: FH Steyr-Homepage'
        },
        {
            imgsrc: 'assets/referenzen/oetscher.jpg',
            imgalt: 'Ladestation Ötscher Bergbahnen',
            title: 'Ötscher - Sagenhaft Alpin',
            text: 'Die Ötscher Bergbahnen verwenden den "Charger 43" mit seinen 8 verschließbaren Handy Ladefächern und dem integrierten 43" Display im Sommer- und Winterbetrieb. Besucher werden tagesaktuell mit Webcambildern, Gastro-Öffnungszeiten, Routenvorschlägen usw. versorgt. Zudem soll der Display als Präsentationsfläche für regionale Betriebe dienen.',
            tags: '#Digitoll #Aktuell #Infopoint',
            link: 'http://www.oetscher.at/de/sommer',
            linkname: 'Link: Ötscher Homepage'
        },
        {
            imgsrc: 'assets/referenzen/wu-wien.jpg',
            imgalt: 'Ladestation an der WU Wien',
            title: 'WU - Wirtschaftsuniversität Wien',
            text: 'Audimax meets Chargeandgo. Direkt vorm größten Hörsaal Österreichs haben Studenten in Zukunft die Möglichkeit, ihr Handy zu laden und sich, ab dem Wintersemester 2018/19, über aktuelle Stellenangebote und Firmen via Display zu informieren. Jetzt noch schnell einen begehrten Werbeplatz sichern.',
            tags: '#ÖH #Recruiting #Mittendrin  ',
            link: 'https://www.wu.ac.at/',
            linkname: 'Link: WU Wien'
        },
        {
            imgsrc: 'assets/referenzen/zell-am-see.jpg',
            imgalt: 'Ladestation bei der Bergbahntagung in Zell am See',
            title: 'Zell am See - Bergbahntagung 2018 ',
            text: 'Schnee olé. Das Team von Chargeandgo freut sich nach dem tollen Event über das super Feedback und garantiert Skibegeisterten jetzt schon gratis Handy Lademöglichkeiten verteilt im ganzen Land ab der Wintersaison 2018/19.',
            tags: '#Kalt #Akkuproblem #regionaler Werbedisplay',
            link: 'http://www.seilbahn.net/sn/index.php?i=0&j=1',
            linkname: 'Link: Seilbahnplattform'
        },
        {
            imgsrc: 'assets/referenzen/messe-wieselburg.jpg',
            imgalt: 'Ladestation auf der Messe Wieselburg',
            title: 'Messe Wieselburg - Willkommen im Mostviertel',
            text: 'Die "Ab Hof" Messe gilt europaweit als die wichtigste Messe für Direktvermarkter von bäuerlichen Produkten und Dienstleistungen. Auch bei dieser Veranstaltungen durften die Handy Ladestationen von Chargeandgo nicht fehlen.',
            tags: '#Infodisplay #After-Messe-Party #Most&Fleischbrot',
            link: 'https://www.instagram.com/p/BfyF-dvhwmS/?hl=de&taken-by=messewieselburg',
            linkname: 'Link: Messe Wieselburg - Instagram'
        },
        {
            imgsrc: 'assets/referenzen/fh-graz.jpg',
            imgalt: 'Ladestation auf der FH Graz',
            title: 'Campus 02 - FH der Wirtschaft in Graz',
            text: 'Als eine der Top Bildungseinrichtungen der Steiermark verfügt der Campus 02 nun auch über eine Handy Ladestation von Chargeandgo. Diese kommt im täglichen Betrieb in der Aula und bei diversen Veranstaltungen zum Einsatz. Neben der Handyladefunktion dient das Gerät vor allem auch Firmen als Präsentationsfläche bei Studenten. ',
            tags: '#Student-trifft-Unternehmen #Service #Aktuelles',
            link: 'https://www.facebook.com/pg/FHCAMPUS02/posts/',
            linkname: 'Link: Campus 02 - Facebook Artikel'
        },
        {
            imgsrc: 'assets/referenzen/messe-wels.jpg',
            imgalt: 'Ladestation auf Messe Wels',
            title: 'Messe Wels - Kleine Stadt ganz groß',
            text: 'Mit knapp 100.000 Besuchern und über 800 Ausstellern ist die Energiesparmesse beispielsweise die größte Häuselbauermesse Österreichs. Die 8 Handy Ladestationen von Chargeandgo verteilt am Messegelände dienen dabei als Besucherservice und Infopoint (Kommende Veranstaltungen, Geländeplan, etc), sowie als Präsentationsfläche für Aussteller der jeweiligen Messe zugleich. ',
            tags: '#Ganztags #Akku-leer #Geländeplan',
            link: 'https://energiesparmesse.at/ne13/?pn=6170028&sn=3025&ly=6&nid=3119',
            linkname: 'Link: Messe Wels - Handy Ladestationen'
        },
        {
            imgsrc: 'assets/referenzen/fh-wels.jpg',
            imgalt: 'Ladestation auf der FH Wels',
            title: 'FH Wels - Technik und Angewandte Naturwissenschaften',
            text: 'Langer Tag voll mit Vorlesungen und die Taschen sind jetzt schon gerammelt voll? Ab dem Sommer Semester 2018 ersparen sich Studenten dank Chargeandgo zumindest das Handy Ladekabel. Neben Infos von der FH, ÖH und dem Alumni-Club erhalten auch Firmen die Möglichkeit, direkt am Campus mit den Studenten via Jobanzeigen oder einer kurzen Firmenpräsentation in Kontakt zu treten. ',
            tags: '#Aula #Infopoint #Aufmerksamkeit',
            link: 'https://www.fh-ooe.at/campus-wels/',
            linkname: 'Link: FH Wels-Homepage'
        },
        {
            imgsrc: 'assets/referenzen/happyfit.jpg',
            imgalt: 'Ladestation in einer Happy Fit Filiale',
            title: 'Happy Fit - Premium Qualität zum fittesten Preis',
            text: 'Als eine der größten Fitnessketten Österreichs stattete Happy Fit Anfang des Jahres alle seine Studios mit Handy Ladestationen der Marke Chargeandgo aus. Durch den integrierten 43" HD-Display der für Werbeeinschaltungen von Unternehmen jeglicher Art genutzt werden kann entstand zudem ein attraktives Werbenetzwerk.',
            tags: '#Werbefläche #Social-Media-Verknüpfung #Iron',
            link: 'https://www.happyfit.eu/blog/handyladestationen-neu-allen-studios',
            linkname: 'Link: Happy Fit - Handy Ladestationen'
        },
    ];

  constructor(private util:UtilitiesService) {
      this.util.setMeta(
        'Referenzen zu unseren Smartphone-Ladestationen',
        'Dass wir nur die besten Ladestationen und ideale Werbeflächen für Sie bieten, davon sind nicht nur wir überzeugt. Auch zig Referenzkunden stimmen uns zu.'
      );
  }

  ngOnInit() {
  }

}
