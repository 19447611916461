import { UtilitiesService } from './../../services/utilities.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.css']
})
export class ImpressumComponent implements OnInit {

  constructor(private util: UtilitiesService) { 
      this.util.setMeta('Impressum');
  }

    ngOnInit() {
    }

}
