import { Component, OnInit, Input } from '@angular/core';
import { EmailKontaktService } from './../../services/emailKontakt.service';

@Component({
  selector: 'app-CTA',
  templateUrl: './CTA.component.html',
  styleUrls: ['./CTA.component.css']
})
export class CTAComponent implements OnInit {

    @Input() hideFolder:boolean = false;
    @Input() customFolder:{ path:string; filename:string };

    constructor(private emailService: EmailKontaktService) { }

    ngOnInit() {
    }

    toggleEmailForm() {
        this.emailService.toggleEmailForm();
    }

}
