import { Injectable, Inject } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';

@Injectable({
  providedIn: 'root'
})
export class CookieNotificationService {

    showNotification:boolean = false;

    constructor(@Inject(WINDOW) private window:Window, @Inject(LOCAL_STORAGE) private localStorage: any) { }

    checkCookie() {
        if(!this.localStorage.getItem('cag-accepted')) {
            this.showNotification = true;
        }
    }

    accept() {
        this.showNotification = false;
        this.window.localStorage.setItem('cag-accepted', 'true');
    }

}
