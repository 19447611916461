import { LadestationenService } from './../../services/ladestationen.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produktuebersicht',
  templateUrl: './produktuebersicht.component.html',
  styleUrls: ['./produktuebersicht.component.css']
})
export class ProduktuebersichtComponent implements OnInit {

    constructor(public ladestationen:LadestationenService) { }

    ngOnInit() {
    }
}
