import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

    @Input() images: string[];

    selectedIndex:number = 0;

    constructor() { }
    ngOnInit() { }

    next() {
        if(this.selectedIndex+1 === this.images.length) { this.selectedIndex = 0 }
        else { this.selectedIndex++ }
    }

    previous() {
        if(this.selectedIndex === 0) { this.selectedIndex = this.images.length-1 }
        else { this.selectedIndex-- }
    }

}
