import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailKontaktService {

    visible:boolean = false;
    agreementChecked:boolean = false;
    mailKey = '8n9a7dsfasd12';
    isSending:boolean = false;

    constructor(private http:HttpClient) {
    }

    toggleEmailForm() {
        this.visible = !this.visible;
    }

    sendEmail(email:string, phone:string) {
        if(email.length > 0 || phone.length > 0) {
            if(this.agreementChecked) {

                this.isSending = true;
                if(phone.length === 0) { phone = '-' }
                if(email.length === 0) { email = '-' }

                this.http.get(`https://mailer.chargeandgo.at/mail/${email}/${phone}/${this.mailKey}`).toPromise()
                .then((res:any) => {
                    this.isSending = false;
                    if(res.statusText === 'OK') {
                        this.toggleEmailForm();
                        this.agreementChecked = false;
                        alert('Vielen Dank für Ihre Anfrage. Wir melden uns in Kürze.');
                    }
                    else {
                        alert('Leider ist etwas ist schiefgelaufen. Bitte versuchen Sie es in wenigen Minuten erneut erneut.');
                    }
                })
                .catch((res:any) => {
                    this.isSending = false;
                    if(res.statusText === 'OK') {
                        this.toggleEmailForm();
                        this.agreementChecked = false;
                        alert('Vielen Dank für Ihre Anfrage. Wir melden uns in Kürze.');
                    }
                    else {
                        alert('Leider ist etwas ist schiefgelaufen. Bitte versuchen Sie es in wenigen Minuten erneut erneut.');
                    }
                });
            }
            else {
                alert('Bitte stimmen Sie der Datenverwendung zu!');
            }
        }
        else {
            alert('Bitte geben Sie entweder eine Emailadresse oder eine Telefonnummer ein.');
        }
    }
}
