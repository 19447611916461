import { UtilitiesService } from './../../services/utilities.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ladestationen',
  templateUrl: './ladestationen.component.html',
  styleUrls: ['./ladestationen.component.css']
})
export class LadestationenComponent implements OnInit {

  constructor(private util:UtilitiesService) {
      this.util.setMeta(
        'Alle Ladestationen und Handy-Charger-Modelle',
        'Alle Handyladestationen und Smartphone-Charger im Überblick. Ideal für den Wohlfühlfaktor und die Zufriedenheit Ihrer Kunden.'
      );
  }

  ngOnInit() {
  }

}
