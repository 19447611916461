import { Component, OnInit } from "@angular/core";
import { UtilitiesService } from "./../../services/utilities.service";

@Component({
  selector: "app-werben",
  templateUrl: "./werben.component.html",
  styleUrls: ["./werben.component.css"]
})
export class WerbenComponent implements OnInit {
  center = {
    lat: 47.824848,
    lng: 14.710156
  };
  zoom = 8;

  markers = [
    { coords: [48.2548838, 13.033534], label: "Fitnessstudio Braunau" },
    { coords: [48.2161597, 14.4600271], label: "Fitnessstudio Enns" },
    { coords: [48.5093731, 14.4991865], label: "Fitnessstudio Freistadt" },
    { coords: [47.0548391, 15.4252869], label: "Fitnessstudio Graz" },
    { coords: [48.2295797, 13.8385612], label: "Fitnessstudio Grieskirchen" },
    { coords: [48.1953074, 14.2459293], label: "Fitnessstudio Haid" },
    { coords: [48.4126112, 15.6046613], label: "Fitnessstudio Krems" },
    { coords: [48.2501884, 14.2445639], label: "Fitnessstudio Leonding" },
    { coords: [48.3048667, 14.2880688], label: "Fitnessstudio Linz" },
    { coords: [48.3256347, 14.3201449], label: "Fitnessstudio Linz" },
    { coords: [48.2508966, 14.3186302], label: "Fitnessstudio Linz" },
    { coords: [48.1937182, 14.1107275], label: "Fitnessstudio Marchtrenk" },
    { coords: [48.2490926, 14.6346741], label: "Fitnessstudio Perg" },
    {
      coords: [48.2093222, 13.5227296],
      label: "Fitnessstudio Ried im Innkreis"
    },
    { coords: [48.0382317, 14.3113688], label: "Fitnessstudio Sierning" },
    { coords: [48.0715645, 14.4279402], label: "Fitnessstudio Stadtgut Steyr" },
    { coords: [48.0365576, 14.4245606], label: "Fitnessstudio Steyr" },
    { coords: [48.0034745, 13.6701187], label: "Fitnessstudio Vöcklabruck" },
    { coords: [48.3456022, 14.1591797], label: "Fitnessstudio Walding" },
    { coords: [48.1608061, 14.0163001], label: "Fitnessstudio Wels" },
    { coords: [48.1680776, 15.0837439], label: "Fitnessstudio Ybbs" },
    { coords: [48.161381, 14.0254493], label: "FH Wels" },
    { coords: [48.042985, 14.4156424], label: "FH Steyr" },
    {
      coords: [48.336614, 14.3171163],
      label: "Johannes Kepler Universität Linz"
    },
    { coords: [48.2134275, 16.4062779], label: "WU Wien" },
    { coords: [47.3847372, 15.0908314], label: "Universität Leoben" },
    { coords: [47.0894341, 15.4379368], label: "Campus 02 Graz" },
    { coords: [48.2392867, 16.3751354], label: "FH Technikum" },
    { coords: [48.2140706, 16.3556204], label: "Uni Wien NIG" },
    { coords: [48.2168127, 16.3583238], label: "Uni Wien AAI" },
    { coords: [48.2191999, 16.401154], label: "FH BFI Wien" },
    { coords: [47.0584231, 15.457955], label: "TU Graz (Inffeldgasse)" },
    { coords: [47.078079, 15.4483296], label: "Uni Graz (ReSoWi)" },
    { coords: [46.6112724, 13.8811773], label: "FH Kärnten" },
    { coords: [47.7888027, 13.0584497], label: "Salzburg Nawi" },
    { coords: [47.2693871, 11.396754], label: "Innsbruck Sowi" }
  ];

  images = [
    "/assets/werben-images/1.jpg",
    "/assets/werben-images/2.jpg",
    "/assets/werben-images/3.jpg",
    "/assets/werben-images/4.jpg",
    "/assets/werben-images/5.jpg",
    "/assets/werben-images/6.jpg",
    "/assets/werben-images/7.jpg",
    "/assets/werben-images/8.jpg",
    "/assets/werben-images/9.jpg",
    "/assets/werben-images/10.jpg",
    "/assets/werben-images/11.jpg"
  ];

  constructor(private util: UtilitiesService) {
    this.util.setMeta(
      "Employer Branding und Werbung mit Ladestationen",
      "Nachhaltiges Employer Branding oder auch Kundenzufriedenheit und Werbewert sind wichtige Faktoren. Mit unseren Handyladegeräten decken Sie alles davon ab."
    );
  }
  ngOnInit() {}

  style = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#009339"
        },
        {
          weight: 3.5
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd"
        }
      ]
    },
    {
      featureType: "administrative.locality",
      elementType: "labels",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#009339"
        },
        {
          weight: 3.5
        }
      ]
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#009339"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    }
  ];
}
