import { UtilitiesService } from './../../services/utilities.service';
import { LadestationenService } from './../../services/ladestationen.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

    station:any;

    constructor(
        public ladestationen: LadestationenService,
        private activatedRoute: ActivatedRoute,
        private util: UtilitiesService) { 
            this.activatedRoute.params.subscribe((params) => {
                
                this.station = this.ladestationen.products.find(function(item) {
                    return item.linkslug === params.produkt;
                });

                this.util.setMeta(this.station.label);

            });
        }

    ngOnInit() {
    }

}
