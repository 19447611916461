import { Component, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { iif } from 'rxjs';
import { UtilitiesService } from './../services/utilities.service';
import { WINDOW } from '@ng-toolkit/universal';

declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
     // for window scroll events
    title = 'Chargeandgo | Ihre Handyladestation aus Österreich | abschließbar';
    scrollPercentage:number = 0;
    hasScrolled:boolean = false;

    constructor(private router: Router,
      private util: UtilitiesService,
      @Inject(WINDOW) private window:Window) {}

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.window.scrollTo(0, 0)
        });
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        const verticalOffset = this.window.pageYOffset
          || this.window.document.documentElement.scrollTop
          || this.window.document.body.scrollTop || 0;

        let height = Math.max( this.window.document.body.scrollHeight, this.window.document.body.offsetHeight, this.window.document.documentElement.clientHeight, this.window.document.documentElement.scrollHeight, this.window.document.documentElement.offsetHeight ) - this.window.document.documentElement.clientHeight;
        this.scrollPercentage = +((verticalOffset*100)/(height)).toFixed(0);

        if(verticalOffset > 100) { this.util.hasScrolled = true; }
        else { this.util.hasScrolled = false; }
    }
}
