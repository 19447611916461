import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitiesService } from './../../services/utilities.service';

declare var window: any;

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css']
})
export class MenubarComponent implements OnInit {

    menuExpanded:boolean = false;

    menu = [
        { label: 'Übersicht', link: '' },
        { label: 'Ladestationen', link: 'produkte' },
        { label: 'Employer Branding', link: 'werbung-schalten' },
        { label: 'Referenzen', link: 'referenzen' }
    ];

    constructor(private router: Router,
      public util: UtilitiesService) { }

    ngOnInit() {
    }

    toggleMenu() {
        this.menuExpanded = !this.menuExpanded;
    }

    navigate(link: string) {

        if (link && link.indexOf('werbung-schalten') !== -1) {
            window.location = 'https://eb.chargeandgo.at/';
        }

        this.router.navigateByUrl(link);
    }

    jumpToPage(link: string) {
        if (link && link.indexOf('werbung-schalten') !== -1) {
            window.location = 'https://eb.chargeandgo.at/';
        }

        this.router.navigateByUrl(link);
        this.toggleMenu();
    }

}
