import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

    hasScrolled:boolean = false;

    constructor(
        private meta: Meta,
        private title:Title) {

    }

    author = `Kobus OG`;
    keywords = `Laden,Handy,Smartphone,Strom,Stecker`;
    description = 'Abschließbare Handyladestationen aus Österreich. Für alle Smartphones (iPhone, Android, USB-C). Der ideale Service für Ihre Kunden.';
    language = `DE`;
    robots = `index, follow`;
    titleFixed = `Chargeandgo.at`;
    setMeta(title:string, description?:string) {
        this.title.setTitle(`${title} | ${this.titleFixed}`);

        let desc = this.description;
        if(description !== undefined) { desc = description }

        this.meta.updateTag({ name: 'description', content: desc })
        this.meta.updateTag({ name: 'author', content: this.author })
        this.meta.updateTag({ name: 'keywords', content: this.keywords })
        this.meta.updateTag({ name: 'language', content: this.language })
        this.meta.updateTag({name: 'robots', content: this.robots})
    }



}
