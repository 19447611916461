import { ReferenzenComponent } from "./../pages/referenzen/referenzen.component";
import { TreeComponent } from "./../components/tree/tree.component";
import { CookieNotificationService } from "./../services/cookieNotification.service";
import { CookieNotificationComponent } from "./../components/cookieNotification/cookieNotification.component";
import { LadestationenService } from "./../services/ladestationen.service";
import { CTAComponent } from "./../components/CTA/CTA.component";
import { ProduktuebersichtComponent } from "./../components/produktuebersicht/produktuebersicht.component";
import { DetailsComponent } from "./../pages/details/details.component";
import { DatenschutzComponent } from "./../pages/datenschutz/datenschutz.component";
import { EmailFormComponent } from "./../components/emailForm/emailForm.component";
import { EmailKontaktService } from "./../services/emailKontakt.service";
import { ProgressLineComponent } from "./../components/progressLine/progressLine.component";
import { FooterComponent } from "./../components/footer/footer.component";
import { MenubarComponent } from "./../components/menubar/menubar.component";
import { UtilitiesService } from "./../services/utilities.service";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StartComponent } from "./../pages/Start/Start.component";
import { ImpressumComponent } from "./../pages/impressum/impressum.component";
import { LadestationenComponent } from "./../pages/ladestationen/ladestationen.component";
import { WerbenComponent } from "./../pages/werben/werben.component";
import { CarouselComponent } from "./../components/carousel/carousel.component";

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    WerbenComponent,
    MenubarComponent,
    FooterComponent,
    ProgressLineComponent,
    EmailFormComponent,
    DatenschutzComponent,
    ImpressumComponent,
    LadestationenComponent,
    DetailsComponent,
    ProduktuebersichtComponent,
    CTAComponent,
    CookieNotificationComponent,
    TreeComponent,
    ReferenzenComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    HttpClientModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDguzsCbvS4aTT7yZX7e9A94YtaZCMnE1s"
    })
  ],
  providers: [
    UtilitiesService,
    EmailKontaktService,
    LadestationenService,
    CookieNotificationService
  ]
})
export class AppModule {}
